const serverBaseSSL = `${process.env.REACT_APP_API_END_POINT}`;
const serverDownloadPDF = `${process.env.REACT_APP_API_PDF_ENDPOINT}`;

const UrlConstant = {
    HOZEN_API_URL: `${serverBaseSSL}/api`,
    HOZEN_DOWNLOAD_SERVER: `${serverDownloadPDF}/api`,
    HOZEN_HOST_URL: `${serverBaseSSL}`,
};

export default UrlConstant;
